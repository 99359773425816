import React from 'react';
import { FormattedMessage } from 'react-intl';
import { UserRoleEnum } from '../../../types/user';

export const nameLabel = (
  <FormattedMessage id="Common.name" defaultMessage="Имя" />
);
export const departmentLabel = (
  <FormattedMessage id="Common.department" defaultMessage="Подразделение" />
);
export const surnameLabel = (
  <FormattedMessage id="Common.surname" defaultMessage="Фамилия" />
);
export const middleNameLabel = (
  <FormattedMessage id="Common.middleName" defaultMessage="Отчество" />
);
export const loginLabel = (
  <FormattedMessage id="login.Login" defaultMessage="Логин" />
);
export const passwordLabel = (
  <FormattedMessage id="Common.password" defaultMessage="Пароль" />
);
export const roleLabel = (
  <FormattedMessage id="Settings.role" defaultMessage="Роль" />
);
export const phoneNumberLabel = (
  <FormattedMessage id="Common.phoneNumber" defaultMessage="Номер телефона" />
);
export const emailLabel = (
  <FormattedMessage id="Common.email" defaultMessage="Электронная почта" />
);
export const dateOfBirthLabel = (
  <FormattedMessage id="Common.dateOfBirth" defaultMessage="Дата рождения" />
);
export const saveLabel = (
  <FormattedMessage id="Common.save" defaultMessage="Сохранить" />
);
export const userLabel = (
  <FormattedMessage id="Common.user" defaultMessage="Пользователь" />
);
export const redactorLabel = (
  <FormattedMessage id="Common.redactor" defaultMessage="Редактор" />
);
export const administratorLabel = (
  <FormattedMessage id="Common.administrator" defaultMessage="Администратор" />
);
export const auditorLabel = (
  <FormattedMessage id="Common.auditor" defaultMessage="Аудитор" />
);
export const fullNameLabel = (
  <FormattedMessage id="Common.fullName" defaultMessage="Ф.И.О." />
);
export const groupsLabel = (
  <FormattedMessage id="Common.groups" defaultMessage="Группы пользователей" />
);

export const lastEntranceLabel = (
  <FormattedMessage
    id="Common.lastEntrance"
    defaultMessage="Последний вход (МСК)"
  />
);

export const addAccountLabel = (
  <FormattedMessage
    id="Settings.addUserAccount"
    defaultMessage="Добавить учетную запись"
  />
);

export const addGroupLabel = (
  <FormattedMessage
    id="Settings.addGroup"
    defaultMessage="Добавить группу пользователей"
  />
);

export const editAccountLabel = (
  <FormattedMessage
    id="Settings.editUserAccount"
    defaultMessage="Редактирование учетной записи"
  />
);

export const editGroupLabel = (
  <FormattedMessage
    id="Settings.editGroup"
    defaultMessage="Редактирование группы пользователей"
  />
);

export const groupNameLabel = (
  <FormattedMessage id="Settings.groupName" defaultMessage="Название группы" />
);

export const userGroupNameLabel = (
  <FormattedMessage
    id="Settings.userGroupName"
    defaultMessage="Название группы пользователей"
  />
);

export const addedDashboardsLabel = (
  <FormattedMessage
    id="Settings.addedDashboards"
    defaultMessage="Добавленные дашборды"
  />
);

export const addedUsersLabel = (
  <FormattedMessage
    id="Settings.addedUsers"
    defaultMessage="Добавленные пользователи"
  />
);

export const enabledLabel = (
  <FormattedMessage id="Common.active" defaultMessage="Активен" />
);

export const selectRolePlaceholder = (
  <FormattedMessage id="Settings.selectRole" defaultMessage="Выбрать роль" />
);

export const roleLabels = {
  [UserRoleEnum.ADMIN]: administratorLabel,
  [UserRoleEnum.EDITOR]: redactorLabel,
  [UserRoleEnum.USER]: userLabel,
  [UserRoleEnum.AUDITOR]: auditorLabel,
};
