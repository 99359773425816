import React from 'react';
import styles from './PrintModal.module.less';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { TemplateType } from '../../../types/dashboard';
import cn from 'classnames';
import {
  dateTimeSeparatedFormat,
  formatWithTimezone,
  serverDateFullTimeFormat,
} from '../../../../../utils/dateFormats';
import { useIntl } from 'react-intl';
import { Col, Row, Typography } from 'antd';

interface ITemplateItemProps {
  onDelete: () => void;
  onEdit: () => void;
  onItemClick: () => void;
  info: TemplateType;
  isActive: boolean;
  isEditActive: boolean;
}

const TemplateItem = ({
  onDelete,
  onEdit,
  onItemClick,
  info,
  isActive,
  isEditActive,
}: ITemplateItemProps) => {
  const intl = useIntl();

  const containerStyles = cn(styles.templateItem, {
    [styles.activeItem]: isActive,
  });

  const editTitle = intl.formatMessage({
    id: 'SendingTemplate.Edit',
    defaultMessage: 'Редактировать шаблон',
  });

  const deleteTitle = intl.formatMessage({
    id: 'SendingTemplate.Delete',
    defaultMessage: 'Удалить шаблон',
  });

  const editStyles = cn(styles.btn, { [styles.activeBtn]: isEditActive });

  return (
    <div className={containerStyles}>
      <button title={deleteTitle} className={styles.btn}>
        <DeleteOutlined onClick={onDelete} />
      </button>
      <button title={editTitle} className={editStyles}>
        <EditOutlined onClick={onEdit} />
      </button>
      <Row onClick={onItemClick}>
        <Col span={17}>
          <Typography.Text
            ellipsis={{ tooltip: true }}
            className={styles['template-label']}
          >
            {info.name}
          </Typography.Text>
        </Col>
        <Col span={24}>
          <div className={styles.info}>
            {info.author} |{' '}
            {formatWithTimezone(
              info?.createDate,
              dateTimeSeparatedFormat,
              serverDateFullTimeFormat,
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TemplateItem;
