import React from 'react';
import { Statistic } from 'antd';
import { PlainObject } from '../../../../types/common';
import { StatisticWidgetConfig } from '../../types/statistic';
import { statisticWidgetIconMap } from '../../helper';
import './statistic.less';

interface StatisticWidgetProps {
  data: PlainObject[];
  config: StatisticWidgetConfig;
}

const getStatisticProps = (
  config: StatisticWidgetProps['config'],
  data: StatisticWidgetProps['data'],
) => {
  if (data?.length > 0) {
    const [first] = data;

    const result = {
      ...config,
      ...first,
    };

    if (first?.valueStyle) {
      result.valueStyle = JSON.parse(first.valueStyle);
    }

    return result;
  }

  return config;
};

const getPrefixIcon = (params: StatisticWidgetConfig) => {
  if (params?.prefix) {
    return statisticWidgetIconMap[params.prefix] || null;
  }

  return null;
};

const StatisticWidget: React.FC<StatisticWidgetProps> = ({
  data = [],
  config,
}) => {
  const statisticProps = getStatisticProps(config, data);

  switch (statisticProps.statisticType) {
    case 'countdown':
      return <Statistic.Countdown {...statisticProps} />;

    default:
      return (
        <Statistic {...statisticProps} prefix={getPrefixIcon(statisticProps)} />
      );
  }
};

export default StatisticWidget;
