import React from 'react';
import { DataType } from '../../../types/dashboard';
import TemplateView from './TemplateView';
import AddEditTemplate from './AddEditTemplate';

import { templateById } from '../../../dashboardSlice';
import { WidgetTypes } from '../../../../../components/Widget/types/common';
import { useAppSelector } from '../../../../../store';

interface ITemplateModalProps {
  onClose: () => void;
  isView: boolean;
  templateId?: string;
  selected: {
    name: string;
    id: string;
    dataType: DataType;
    widgetType?: WidgetTypes;
  };
}

const TemplateModal = ({
  onClose,
  isView,
  templateId,
  selected,
}: ITemplateModalProps) => {
  const template = useAppSelector(state => templateById(state, templateId));

  return !isView ? (
    <AddEditTemplate
      template={template}
      selected={selected}
      onClose={onClose}
    />
  ) : (
    <TemplateView template={template} />
  );
};

export default TemplateModal;
