import React, { memo, ReactText } from 'react';
import { TableWidgetConfig } from '../../types/table';

import styles from './TableWidget.module.less';

import FloatTable from './FloatTable';
import PivotTable from './PivotTable';
import { Empty } from 'antd';

interface TableWidgetProps {
  data: Record<string, any>[];
  config: TableWidgetConfig;
  parameter: ReactText[] | string;
  onChange: (value: any) => void;
  isEditing: boolean;
  onChangeSettings: (value: string) => void;
}

const TableWidget = ({
  data,
  config,
  parameter,
  onChange,
  isEditing,
  onChangeSettings,
}: TableWidgetProps) => {
  if (!data?.length) {
    return (
      <Empty
        className={styles.emptyData}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
      />
    );
  }

  if (config.isPivot) {
    return (
      <PivotTable
        data={data}
        config={config}
        parameter={parameter}
        onChange={onChange}
        isEditing={isEditing}
        onChangeSettings={onChangeSettings}
      />
    );
  }

  return (
    <FloatTable
      data={data}
      config={config}
      parameter={parameter}
      onChange={onChange}
    />
  );
};

const MemoizedTableWidget = memo(TableWidget);

export default MemoizedTableWidget;
