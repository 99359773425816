import { ReactNode } from 'react';
import {
  AggregationTypes,
  AggregatorsSetType,
  Column,
  DataItem,
  DataTypes,
} from '../../types/table';
import { intl } from '../../../../utils/intl';
import {
  checkFormat,
  formatDuration,
  tableDateFormat,
  tableTimeFormat,
} from './helpers';
import moment from 'moment';

const initialDuration = '00:00:00';

const sum = (values: ReactNode[]) =>
  values.reduce(
    (acc: number, el) => (Number.isFinite(el) ? acc + (el as number) : acc),
    0,
  );

export const timeSum = (values: ReactNode[]) => {
  const filtered = values.filter(el =>
    checkFormat(String(el), tableTimeFormat),
  ) as string[];
  const sumOfDuration = filtered.reduce(
    (acc, el) => acc.add(moment.duration(el)),
    moment.duration(initialDuration),
  );
  return formatDuration(sumOfDuration);
};

const min = (values: ReactNode[]) => {
  const filtered = values.filter(el => Number.isFinite(el)) as number[];
  return filtered.length ? Math.min(...filtered) : 0;
};

export const minMaxDate = (
  values: ReactNode[],
  type: 'min' | 'max',
  format: string = tableDateFormat,
) => {
  const filtered = values.filter(el =>
    checkFormat(String(el), format),
  ) as string[];
  const formattedToMoment = filtered.map(el => moment(el, format));
  return moment[type](formattedToMoment).format(format);
};

const max = (values: ReactNode[]) => {
  const filtered = values.filter(el => Number.isFinite(el)) as number[];
  return filtered.length ? Math.max(...filtered) : 0;
};

const average = (values: ReactNode[]) => {
  const filtered = values.filter(el => Number.isFinite(el));
  return filtered.length ? sum(filtered) / filtered.length : 0;
};

export const averageTime = (values: ReactNode[]) => {
  const filtered = values.filter(el =>
    checkFormat(String(el), tableTimeFormat),
  ) as string[];

  const sumAsMilliseconds = moment
    .duration(timeSum(filtered))
    .as('milliseconds');

  return filtered.length
    ? moment.utc(sumAsMilliseconds / filtered.length).format(tableTimeFormat)
    : initialDuration;
};

const count = (values: ReactNode[]) => {
  const filtered = values.filter(el => el ?? el);
  return filtered.length;
};

export const numAggregators: AggregatorsSetType = {
  [AggregationTypes.sum]: sum,
  [AggregationTypes.min]: min,
  [AggregationTypes.max]: max,
  [AggregationTypes.average]: average,
  [AggregationTypes.count]: count,
};

export const dateAggregators: Partial<AggregatorsSetType> = {
  [AggregationTypes.min]: (values: ReactNode[]) => minMaxDate(values, 'min'),
  [AggregationTypes.max]: (values: ReactNode[]) => minMaxDate(values, 'max'),
  [AggregationTypes.count]: count,
};

export const otherAggregators: Partial<AggregatorsSetType> = {
  [AggregationTypes.count]: count,
};

export const timeAggregators: AggregatorsSetType = {
  [AggregationTypes.sum]: timeSum,
  [AggregationTypes.min]: (values: ReactNode[]) =>
    minMaxDate(values, 'min', tableTimeFormat),
  [AggregationTypes.max]: (values: ReactNode[]) =>
    minMaxDate(values, 'max', tableTimeFormat),
  [AggregationTypes.average]: averageTime,
  [AggregationTypes.count]: count,
};

const getAggregators = (values: ReactNode[]): Partial<AggregatorsSetType> => {
  const hasNum = values.some(el => Number.isFinite(el));
  const hasDate = values.some(el => checkFormat(String(el), tableDateFormat));
  const hasTime = values.some(el => checkFormat(String(el), tableTimeFormat));
  if (hasDate) {
    return dateAggregators;
  } else if (hasTime) {
    return timeAggregators;
  } else if (hasNum) {
    return numAggregators;
  }
  return otherAggregators;
};

const minLabel = intl.formatMessage({
  id: 'Format.Min',
  defaultMessage: 'Мин.',
});
const maxLabel = intl.formatMessage({
  id: 'Format.Max',
  defaultMessage: 'Макс.',
});
const averageLabel = intl.formatMessage({
  id: 'Format.Average',
  defaultMessage: 'Среднее',
});
const countLabel = intl.formatMessage({
  id: 'Format.Count',
  defaultMessage: 'Количество',
});
const sumLabel = intl.formatMessage({
  id: 'Format.Sum',
  defaultMessage: 'Сумма',
});

export const aggregationDropdownItems: Record<AggregationTypes, string> = {
  [AggregationTypes.sum]: sumLabel,
  [AggregationTypes.max]: maxLabel,
  [AggregationTypes.min]: minLabel,
  [AggregationTypes.average]: averageLabel,
  [AggregationTypes.count]: countLabel,
};

const aggregationTypesByDataTypes: Record<
  DataTypes,
  Partial<AggregatorsSetType>
> = {
  [DataTypes.NUMBER]: numAggregators,
  [DataTypes.DATE]: dateAggregators,
  [DataTypes.TIME]: timeAggregators,
  [DataTypes.OTHER]: otherAggregators,
};

export const getAggregationTypes = (dataType: DataTypes = DataTypes.OTHER) =>
  (
    Object.keys(aggregationTypesByDataTypes[dataType]) as AggregationTypes[]
  ).reduce(
    (acc, key) => ({
      ...acc,
      [key]: aggregationDropdownItems[key],
    }),
    {},
  );

export const formatTotal = (values: ReactNode[], type?: AggregationTypes) => {
  const aggregator = getAggregators(values);
  const defaultType = type || (Object.keys(aggregator)[0] as AggregationTypes);
  return aggregator?.[defaultType]?.(values);
};

export const calculateTotals = (
  data: DataItem[],
  valueTotalTypes: Record<string, AggregationTypes>,
) => {
  const grouped = data.reduce<Record<Column['dataIndex'], any[]>>((acc, el) => {
    Object.keys(el).forEach(key =>
      Array.isArray(acc[key]) ? acc[key].push(el[key]) : (acc[key] = [el[key]]),
    );
    return acc;
  }, {});
  return Object.keys(grouped).reduce<DataItem>(
    (acc, key) => ({
      ...acc,
      [key]: formatTotal(grouped[key], valueTotalTypes[key]),
    }),
    {},
  );
};
