import React, { useEffect, useState, memo, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import {
  deleteWidgetError,
  selectCurrentDashboard,
  selectWidgetData,
  selectWidgetErrors,
  setWidgetError,
  updateWidgetGrid,
} from '../../features/dashboard/dashboardSlice';
import { useAppDispatch, useAppSelector } from '../../store';
import WidgetBody from './components/WidgetBody';
import cn from 'classnames';
import styles from './Widget.module.less';
import { WidgetConfig } from './types/common';
import { WidgetGrid } from '../../features/dashboard/types/dashboard';

interface Props {
  widgetGrid: WidgetGrid;
  isEditing: boolean;
  onChangeParameter: (params: {
    name: string;
    id: string;
    value: string | string[];
  }) => void;
  widgetAccessoryRight: ReactNode;
}

const Widget: React.FC<Props> = ({
  widgetGrid,
  isEditing,
  onChangeParameter,
  widgetAccessoryRight,
}) => {
  const widget = widgetGrid.widget;
  const dashboard = useSelector(selectCurrentDashboard);
  const widgetId = widget.id || '';
  const dispatch = useAppDispatch();
  const widgetError = useAppSelector(state =>
    selectWidgetErrors(state, widgetId),
  );
  const [config, setConfig] = useState<WidgetConfig>({});

  const data = useAppSelector(state => selectWidgetData(state, widgetId));
  // TODO this selector trigger unnecessary renders, need refactor

  const handleChangeParameter = (value: any) => {
    if (widget.id) {
      onChangeParameter({ name: widget.idName, id: widget.id, value });
    }
  };

  const parseConfig = () => {
    try {
      setConfig(JSON.parse(widget.component));
      dispatch(deleteWidgetError(widgetId));
    } catch (_) {
      dispatch(setWidgetError({ id: widgetId, message: 'Cannot parse JSON' }));
    }
  };

  useEffect(() => {
    parseConfig();
  }, [widget.component]);

  const containerStyles = cn(styles.bodyWrap, {
    [styles.bordered]: !config.flat,
  });

  const handleWidgetComponentChange = (component: string) => {
    if (dashboard) {
      dispatch(
        updateWidgetGrid(dashboard.id, {
          ...widgetGrid,
          widget: { ...widgetGrid.widget, component },
        }),
      );
    }
  };

  return (
    <div className={containerStyles}>
      <WidgetBody
        id={widgetId}
        isEditing={isEditing}
        accessoryRight={widgetAccessoryRight}
        widgetType={widget.type}
        data={data}
        handleWidgetComponentChange={handleWidgetComponentChange}
        errorMessage={widgetError?.message}
        config={config}
        onChange={handleChangeParameter}
        parameter={widget.parameter}
      />
    </div>
  );
};

const MemoizedWidget = memo(Widget);

export default MemoizedWidget;
