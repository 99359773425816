import { Button, Table, TableProps } from 'antd';
import React, { memo, ReactText, useMemo } from 'react';
import { addFiltersToColumns, ROW_WITHOUT_COLUMN } from './helpers';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import styles from './TableWidget.module.less';
import { TablePaginationConfig } from 'antd/es/table';
import { DataItem, TableWidgetConfig } from '../../types/table';
import { TableRowSelection } from 'antd/es/table/interface';
import { getDefaultColumns } from '../../helper';
import { totalLabel } from './groupers';

interface FloatTableProps {
  data: Record<string, any>[];
  config: TableWidgetConfig;
  parameter: ReactText[] | string;
  onChange: (value: any) => void;
}

const DEFAULT_PAGE_SIZE: number = 10;

const FloatTable = ({ data, config, parameter, onChange }: FloatTableProps) => {
  const paginationProps: TablePaginationConfig = useMemo(() => {
    return {
      hideOnSinglePage: true,
      position: ['bottomCenter'],
      size: 'small',
      pageSize: config.maxRowCount || DEFAULT_PAGE_SIZE,
    };
  }, [config.maxRowCount]);

  const selectedRowKeys: ReactText[] =
    typeof parameter === 'string' ? [parameter] : parameter;

  const rowSelection: TableRowSelection<any> | undefined = config.rowSelection
    ? {
        selectedRowKeys,
        onChange,
        type: config.rowSelectionType || 'checkbox',
      }
    : undefined;

  const expandIcon: TableProps<FloatTableProps>['expandIcon'] = ({
    expanded,
    onExpand,
    record,
    expandable,
  }) => {
    if (expandable) {
      return expanded ? (
        <Button
          size="small"
          shape="default"
          className={styles.expandButton}
          onClick={e => onExpand(record, e)}
          icon={<MinusOutlined />}
        />
      ) : (
        <Button
          size="small"
          shape="default"
          className={styles.expandButton}
          onClick={e => onExpand(record, e)}
          icon={<PlusOutlined />}
        />
      );
    }
  };

  const defaultColumnsWithFilters = useMemo(() => {
    const defaultColumns = config.columns || getDefaultColumns(data);

    const friendlyTitles = defaultColumns.map(col => {
      const LIMIT_WORDS = 33;

      const isLongString =
        typeof col.title === 'string' && col.title.length > LIMIT_WORDS;

      if (!isLongString) return col;

      const DOTS = ' ...';
      const dottedTitle = col.title.slice(0, LIMIT_WORDS - DOTS.length) + DOTS;

      return {
        ...col,
        title: (<span title={col.title}>{dottedTitle}</span>) as any,
      };
    });

    return addFiltersToColumns(friendlyTitles, data);
  }, [JSON.stringify(data), JSON.stringify(config.columns)]);

  const rowsClassName = (record: DataItem) => {
    const isTotal = record[ROW_WITHOUT_COLUMN] === totalLabel;
    return isTotal ? styles.boldCell : '';
  };

  return (
    <div className={styles.tableWrapper}>
      <Table
        rowSelection={rowSelection}
        pagination={paginationProps}
        bordered={config.isPivot || config.bordered}
        size={config.size}
        rowClassName={rowsClassName}
        expandable={{ expandIcon }}
        columns={defaultColumnsWithFilters}
        dataSource={data}
      />
    </div>
  );
};

const MemoizedFloatTable = memo(FloatTable);

export default MemoizedFloatTable;
