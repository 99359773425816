import React, { ReactNode } from 'react';
import TextWidget from '../../components/TextWidget';
import ChartWidget from '../../components/ChartWidget';
import TableWidget from '../../components/TableWidget';
import DateWidget from '../../components/DateWidget';
import TimeWidget from '../../components/TimeWidget';
import { WidgetConfig, WidgetTypes } from '../../types/common';
import SelectWidget from '../../components/SelectWidget';
import DateTimeWidget from '../../components/DateTimeWidget';
import StatisticWidget from '../../components/StatisticWidget';
import styles from '../../Widget.module.less';
import { untitled } from '../../helper';
import ErrorBoundary from '../../../ErrorBoundary';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import { setWidgetError } from '../../../../features/dashboard/dashboardSlice';
import { Empty } from 'antd';

interface WidgetBodyProps {
  widgetType: WidgetTypes;
  data: any;
  config: WidgetConfig;
  onChange: (value: string | string[]) => void;
  parameter: any;
  accessoryRight?: ReactNode;
  errorMessage?: string;
  id: string;
  isEditing: boolean;
  handleWidgetComponentChange: (component: string) => void;
}

const WidgetBody: React.FC<WidgetBodyProps> = ({
  widgetType,
  data,
  config,
  onChange,
  parameter,
  accessoryRight,
  errorMessage,
  id,
  isEditing,
  handleWidgetComponentChange,
}) => {
  const dispatch = useDispatch();
  const setError = (message: string) =>
    dispatch(setWidgetError({ id, message }));

  const containerStyles = cn(styles.widgetContainer, {
    [styles.error]: !!errorMessage,
  });

  const headerStyles = cn('react-grid-drag-space', styles.header, {
    [styles.movingCursor]: isEditing,
  });

  switch (widgetType) {
    case WidgetTypes.TEXT:
      const textStyles = cn(styles.textWrap, 'react-grid-drag-space', {
        [styles.error]: !!errorMessage,
        [styles.movingCursor]: isEditing,
      });
      return (
        <div className={textStyles}>
          <div className={styles.accessoryWrap}>{accessoryRight}</div>
          <ErrorBoundary setErrorMessage={setError} errorMessage={errorMessage}>
            <TextWidget config={config} data={data} />
          </ErrorBoundary>
        </div>
      );
    case WidgetTypes.CHART:
      return (
        <div className={containerStyles}>
          <div className={headerStyles}>
            <span>{config.title || untitled}</span>
            {!errorMessage && accessoryRight}
          </div>
          <ErrorBoundary setErrorMessage={setError} errorMessage={errorMessage}>
            {!data || !data?.length ? (
              <Empty
                className={styles.emptyData}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            ) : (
              <div
                style={{
                  flex: 1,
                  backgroundColor: 'white',
                  padding: config.padding || 10,
                }}
              >
                <ChartWidget data={data} config={config} />
              </div>
            )}
          </ErrorBoundary>
        </div>
      );

    case WidgetTypes.TABLE:
      return (
        <div className={containerStyles} style={{ overflow: 'auto' }}>
          <div className={headerStyles}>
            <span>{config.title || untitled}</span>
            {!errorMessage && accessoryRight}
          </div>
          <ErrorBoundary setErrorMessage={setError} errorMessage={errorMessage}>
            <TableWidget
              data={data}
              onChangeSettings={handleWidgetComponentChange}
              config={config}
              onChange={onChange}
              parameter={parameter}
              isEditing={isEditing}
            />
          </ErrorBoundary>
        </div>
      );
    case WidgetTypes.DATE:
      return (
        <div className={containerStyles}>
          <div className={headerStyles}>
            <span>{config.title || untitled}</span>
          </div>
          <ErrorBoundary setErrorMessage={setError} errorMessage={errorMessage}>
            <DateWidget
              parameter={parameter}
              config={config}
              onChange={onChange}
            />
          </ErrorBoundary>
        </div>
      );
    case WidgetTypes.TIME:
      return (
        <div className={containerStyles}>
          <div className={headerStyles}>
            <span>{config.title || untitled}</span>
          </div>
          <ErrorBoundary setErrorMessage={setError} errorMessage={errorMessage}>
            <TimeWidget
              parameter={parameter}
              config={config}
              onChange={onChange}
            />
          </ErrorBoundary>
        </div>
      );
    case WidgetTypes.DATETIME:
      return (
        <div className={containerStyles}>
          <div className={headerStyles}>
            <span>{config.title || untitled}</span>
          </div>
          <ErrorBoundary setErrorMessage={setError} errorMessage={errorMessage}>
            <DateTimeWidget
              config={config}
              onChange={onChange}
              parameter={parameter}
            />
          </ErrorBoundary>
        </div>
      );
    case WidgetTypes.SELECT:
      return (
        <div className={containerStyles}>
          <div className={headerStyles}>
            <span>{config.title || untitled}</span>
          </div>
          <ErrorBoundary setErrorMessage={setError} errorMessage={errorMessage}>
            <SelectWidget
              data={data}
              config={config}
              onChange={onChange}
              parameter={parameter}
            />
          </ErrorBoundary>
        </div>
      );
    case WidgetTypes.STATISTIC:
      const statisticErrorMessage =
        errorMessage && `${config.title || untitled}: ${errorMessage}`;
      const statisticStyles = cn(
        styles.statisticContainer,
        'react-grid-drag-space',
        {
          [styles.error]: !!errorMessage,
          [styles.movingCursor]: isEditing,
        },
      );
      return (
        <div className={statisticStyles}>
          <span className={styles.accessoryWrap}>
            {!errorMessage && accessoryRight}
          </span>
          <ErrorBoundary
            style={{ fontSize: 14 }}
            setErrorMessage={setError}
            errorMessage={statisticErrorMessage}
          >
            <StatisticWidget data={data} config={config} />
          </ErrorBoundary>
        </div>
      );
    default:
      const unknownTypeError = 'Unknown widget type';
      return (
        <div className={cn(styles.widgetContainer, styles.error)}>
          <div className={headerStyles}>
            <span>{config.title || untitled}</span>
          </div>
          <ErrorBoundary errorMessage={unknownTypeError}>
            {unknownTypeError}
          </ErrorBoundary>
        </div>
      );
  }
};

export default WidgetBody;
