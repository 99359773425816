import { Button, Table, TableProps } from 'antd';
import React, { memo, ReactText, useEffect, useMemo, useState } from 'react';
import {
  addFiltersToColumns,
  addKeys,
  filteredColumns,
  generatePivotColumns,
  ROW_WITHOUT_COLUMN,
} from './helpers';
import {
  CalculatorOutlined,
  MinusOutlined,
  PlusOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import styles from './TableWidget.module.less';
import { TablePaginationConfig } from 'antd/es/table';
import {
  DataItem,
  Formula,
  PivotGroupConf,
  TableWidgetConfig,
  ValuesPlace,
} from '../../types/table';
import {
  TableCurrentDataSource,
  TableRowSelection,
} from 'antd/es/table/interface';
import { PlainObject } from '../../../../types/common';
import { getDefaultColumns } from '../../helper';
import TableSettingsModal from './TableSettingsModal';
import { pivotDataFormatter, totalLabel } from './groupers';
import { useIntl } from 'react-intl';
import Calculation from './Calculation';

interface PivotTableProps {
  data: Record<string, any>[];
  config: TableWidgetConfig;
  parameter: ReactText[] | string;
  onChange: (value: any) => void;
  isEditing: boolean;
  onChangeSettings: (value: string) => void;
}

const defaultPageSize: number = 10;

const PivotTable = ({
  data,
  config,
  parameter,
  onChange,
  isEditing,
  onChangeSettings,
}: PivotTableProps) => {
  const paginationProps: TablePaginationConfig = useMemo(() => {
    return {
      hideOnSinglePage: true,
      position: ['bottomCenter'],
      size: 'small',
      pageSize: config.maxRowCount || defaultPageSize,
    };
  }, [config.maxRowCount]);

  const intl = useIntl();

  const initialSettings = {
    columns: [],
    values: [],
    rows: [],
    groupBy: {},
    formatters: {},
    valueTotalTypes: {},
    formulas: [],
    showRowTotal: true,
    showColumnTotal: true,
    valuesPlace: ValuesPlace.COLUMNS,
  };

  const [selected, setSelected] = useState<PivotGroupConf>(initialSettings);

  const handleChangeSettings = (values: PivotGroupConf) => {
    setSelected(values);
    onChangeSettings(JSON.stringify({ ...config, settings: values }, null, 2));
  };

  const handleChangeFormula = (formula: Formula) => {
    const values = {
      ...selected,
      formulas: [...selected.formulas, formula],
    };
    handleChangeSettings(values);
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [calculationOpen, setCalculationOpenOpen] = useState(false);

  const onModalOpenToggle = () => setModalOpen(!modalOpen);
  const onCalculationOpenToggle = () =>
    setCalculationOpenOpen(!calculationOpen);

  const [currentDataSource, setCurrentDataSource] = useState<PlainObject[]>([]);

  const [keys, setKeys] = useState<string[]>([]);

  const selectedRowKeys: ReactText[] =
    typeof parameter === 'string' ? [parameter] : parameter;

  const rowSelection: TableRowSelection<any> | undefined = config.rowSelection
    ? {
        selectedRowKeys,
        onChange,
        type: config.rowSelectionType || 'checkbox',
      }
    : undefined;

  const handleChange = (
    _: any,
    __: any,
    ___: any,
    extra: TableCurrentDataSource<PivotTableProps>,
  ) => {
    setCurrentDataSource(extra.currentDataSource);
  };

  const expandIcon: TableProps<PivotTableProps>['expandIcon'] = ({
    expanded,
    onExpand,
    record,
    expandable,
  }) => {
    if (expandable) {
      return expanded ? (
        <Button
          size="small"
          shape="default"
          className={styles.expandButton}
          onClick={e => onExpand(record, e)}
          icon={<MinusOutlined />}
        />
      ) : (
        <Button
          size="small"
          shape="default"
          className={styles.expandButton}
          onClick={e => onExpand(record, e)}
          icon={<PlusOutlined />}
        />
      );
    }
  };

  const dataWithKeys = useMemo(
    () => addKeys(currentDataSource),
    [currentDataSource],
  );

  useEffect(() => {
    setCurrentDataSource(data);
  }, [data]);

  useEffect(() => {
    setSelected({
      ...initialSettings,
      ...config?.settings,
      showColumnTotal: true,
    });
  }, [config?.settings]);

  const pivotDataColumns = useMemo(
    () => pivotDataFormatter(dataWithKeys, selected),
    [JSON.stringify(dataWithKeys), JSON.stringify(selected)],
  );

  const filtered = filteredColumns(
    pivotDataColumns.columns,
    pivotDataColumns.totalColumns,
    keys,
    selected.showColumnTotal,
  );
  const generatedColumns = generatePivotColumns({
    columns: filtered,
    filterKeys: keys,
    setKeys,
  });

  const dataSource = config.isPivot ? pivotDataColumns.data : dataWithKeys;

  const defaultColumnsWithFilters = useMemo(() => {
    const defaultColumns = config.columns || getDefaultColumns(dataWithKeys);

    const friendlyTitles = defaultColumns.map(col => {
      const LIMIT_WORDS = 33;

      const isLongString =
        typeof col.title === 'string' && col.title.length > LIMIT_WORDS;

      if (!isLongString) return col;

      const DOTS = ' ...';
      const dottedTitle = col.title.slice(0, LIMIT_WORDS - DOTS.length) + DOTS;

      return {
        ...col,
        title: (<span title={col.title}>{dottedTitle}</span>) as any,
      };
    });

    return addFiltersToColumns(friendlyTitles, dataWithKeys);
  }, [JSON.stringify(dataWithKeys), JSON.stringify(config.columns)]);

  const columns = config.isPivot ? generatedColumns : defaultColumnsWithFilters;

  const canEditSettings = config.isPivot && isEditing;

  const fieldsTitle = intl.formatMessage({
    id: 'widget.Fields',
    defaultMessage: 'Поля',
  });

  const calculationTitle = intl.formatMessage({
    id: 'Common.Calculation',
    defaultMessage: 'Калькуляция',
  });

  const rowsClassName = (record: DataItem) => {
    const isTotal = record?.[ROW_WITHOUT_COLUMN] === totalLabel;
    return isTotal ? styles.boldCell : '';
  };

  return (
    <>
      {canEditSettings && (
        <div className={styles.settingsButtons}>
          <Button
            onClick={onCalculationOpenToggle}
            shape="circle"
            icon={<CalculatorOutlined />}
            title={calculationTitle}
          />
          <Button
            onClick={onModalOpenToggle}
            shape="circle"
            icon={<SettingOutlined />}
            title={fieldsTitle}
          />
        </div>
      )}
      <div className={styles.tableWrapper}>
        <Table
          rowSelection={rowSelection}
          pagination={paginationProps}
          bordered={config.isPivot || config.bordered}
          size={config.size}
          rowClassName={rowsClassName}
          expandable={{ expandIcon }}
          columns={columns}
          onChange={handleChange}
          dataSource={dataSource}
        />
      </div>
      {canEditSettings && (
        <>
          <TableSettingsModal
            selected={selected}
            setSelected={handleChangeSettings}
            data={data}
            isOpen={modalOpen}
            onClose={onModalOpenToggle}
          />
          <Calculation
            data={data}
            isOpen={calculationOpen}
            setFormula={handleChangeFormula}
            onClose={onCalculationOpenToggle}
          />
        </>
      )}
    </>
  );
};

const MemoizedPivotTable = memo(PivotTable);

export default MemoizedPivotTable;
