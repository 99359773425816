import React, { useEffect, useState } from 'react';
import styles from './PrintModal.module.less';
import EmailSelect, { EmailSelectFormItem } from './EmailSelect';
import {
  Button,
  Col,
  Form,
  Input,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  TimePicker,
} from 'antd';
import { FormattedMessage } from 'react-intl';
import {
  days,
  frequencyOptions,
  typeOptions,
  weekDaysOptions,
} from './helpers';
import {
  CreateEditTemplateType,
  DataType,
  Frequency,
  MonthPeriods,
  TemplateType,
} from '../../../types/dashboard';
import { useSelector } from 'react-redux';
import { selectUserName } from '../../../../user/userSlice';
import moment from 'moment';
import {
  createAndEditTemplate,
  fetchTemplates,
  selectLoadingEditTemplate,
} from '../../../dashboardSlice';
import {
  dateTimeSeparatedFormat,
  fullTimeFormat,
  hoursMinutesFormat,
} from '../../../../../utils/dateFormats';
import { WidgetTypes } from '../../../../../components/Widget/types/common';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { intl } from '../../../../../utils/intl';

interface ITemplateModalProps {
  onClose: () => void;
  template: TemplateType | null;
  selected: {
    name: string;
    id: string;
    dataType: DataType;
    widgetType?: WidgetTypes;
  };
}

const initialValues = {
  name: '',
  createDate: '',
  contacts: [],
  source: {
    id: '',
    frequency: '',
  },
  schedule: {
    days: [],
    calculateDays: [],
  },
  type: typeOptions[0].value,
};

const requiredFieldMessage = intl.formatMessage({
  id: 'Common.requiredField',
  defaultMessage: 'Обязательное поле',
});

const AddEditTemplate = ({
  onClose,
  template,
  selected,
}: ITemplateModalProps) => {
  const currentDate = moment().format(dateTimeSeparatedFormat);
  const sourceType =
    selected.dataType === DataType.Dashboard ? 'DASHBOARD' : 'WIDGET';
  const dispatch = useAppDispatch();
  const loading = useSelector(selectLoadingEditTemplate);
  const [form] = Form.useForm();

  const myName = useAppSelector(state => selectUserName(state));

  const [selectedFrequency, setFrequency] = useState<Frequency>(
    template?.schedule.frequency || Frequency.DAY,
  );

  const author = template?.author || myName || '';

  const createDate = template?.createDate
    ? moment(template?.createDate).format(dateTimeSeparatedFormat)
    : currentDate;

  const handleFinish = (values: CreateEditTemplateType) => {
    const daysFiltered = values.schedule.days?.filter(
      el => el !== MonthPeriods.LAST_DAY,
    );

    const calculateDays = values.schedule.days?.filter(
      el => el === MonthPeriods.LAST_DAY,
    ) as MonthPeriods[];

    const formattedValue = {
      ...template,
      ...values,
      schedule: {
        ...values.schedule,
        calculateDays,
        days: daysFiltered,
        frequency: selectedFrequency,
        time: moment(values.schedule.time).format(fullTimeFormat).toString(),
      },
      author,
      contacts: (values.contacts as string[]).map(i => ({ value: i })),
      source: {
        id: selected.id,
        type: sourceType,
      },
    };

    dispatch(
      createAndEditTemplate(formattedValue, () => {
        dispatch(fetchTemplates(selected.id));
        onClose();
      }),
    );
  };

  const handleFrequencyChange = (e: RadioChangeEvent) => {
    setFrequency(e.target.value);
    form.setFieldValue(['schedule', 'days'], []);
  };

  const defaultValues = {
    ...initialValues,
    ...template,
    schedule: {
      ...template?.schedule,
      time: template?.schedule.time
        ? moment(template?.schedule.time, fullTimeFormat)
        : '',
    },
  };

  const getDays = (schedule: TemplateType['schedule']) => {
    const isMonthly = schedule.frequency === Frequency.MONTH;

    if (isMonthly) {
      const hasLast = schedule?.calculateDays?.includes(MonthPeriods.LAST_DAY);

      if (hasLast) {
        return [...(schedule?.days || []), ...(schedule?.calculateDays || [])];
      }
    }

    return schedule?.days || [];
  };

  useEffect(() => {
    if (template) {
      form.setFieldsValue({
        name: defaultValues.name,
        type: defaultValues.type,
        schedule: {
          frequency: defaultValues.schedule.frequency,
          time: defaultValues.schedule.time,
          days: getDays(template.schedule),
        },
        contacts: (defaultValues?.contacts || []).map(el => el.value),
      });

      setFrequency(defaultValues?.schedule.frequency || Frequency.DAY);
    } else {
      form.resetFields();
    }
  }, [JSON.stringify(defaultValues)]);

  return (
    <Form
      className={styles.formContainer}
      onFinish={handleFinish}
      form={form}
      disabled={loading}
      validateMessages={{
        required: requiredFieldMessage,
      }}
    >
      <div className={styles.section}>
        <Row gutter={6}>
          <Col span={4}>
            <div
              style={{
                lineHeight: '32px',
                fontSize: '16px',
                color: '#969696',
                fontWeight: 500,
              }}
            >
              <FormattedMessage
                id="widgetForm.Name"
                defaultMessage="Название"
              />
            </div>
          </Col>
          <Col span={20}>
            <Form.Item
              name="name"
              initialValue={defaultValues.name}
              rules={[{ required: true }]}
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <div className={styles.sectionItem}>
          <span className={styles.label}>
            <FormattedMessage id="Common.Author" defaultMessage="Автор" />:
          </span>
          <span className={styles.info}>{author}</span>
        </div>
        <div className={styles.sectionItem}>
          <span className={styles.label}>
            <FormattedMessage
              id="Common.CreationDate"
              defaultMessage="Дата создания"
            />
            :
          </span>
          <span className={styles.info}>{createDate}</span>
        </div>
      </div>
      <div className={styles.section}>
        <Form.Item
          name="contacts"
          initialValue={defaultValues.contacts}
          style={{ marginBottom: 0 }}
          rules={[{ required: true }]}
        >
          <EmailSelectFormItem />
        </Form.Item>
      </div>
      <div className={styles.section}>
        <div className={styles.label}>
          <FormattedMessage
            id="Common.SendingFormat"
            defaultMessage="Формат отправления"
          />
        </div>
        <Form.Item
          className={styles.formItem}
          name="type"
          initialValue={defaultValues.type}
        >
          <Radio.Group>
            {typeOptions.map(({ value, label }) => (
              <Radio key={value} value={value}>
                {label}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
      </div>
      <div className={styles.section}>
        <div className={styles.label}>
          <FormattedMessage
            id="Common.SendingFrequency"
            defaultMessage="Периодичность отправления"
          />
        </div>
        <Form.Item
          className={styles.formItem}
          name={['schedule', 'frequency']}
          initialValue={defaultValues.schedule.frequency}
          rules={[{ required: true }]}
        >
          <Radio.Group onChange={handleFrequencyChange}>
            {frequencyOptions.map(({ value, label }) => (
              <Radio key={value} value={value}>
                {label}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
      </div>
      <div className={styles.section}>
        <Row gutter={12}>
          <Col>
            <div
              style={{
                lineHeight: '32px',
                fontSize: '16px',
                color: '#969696',
                fontWeight: 500,
              }}
            >
              <FormattedMessage
                id="Common.SendingTime"
                defaultMessage="Время отправления (МСК)"
              />
            </div>
          </Col>
          <Col span={10}>
            <Form.Item
              name={['schedule', 'time']}
              initialValue={defaultValues.schedule.time}
              rules={[{ required: true }]}
              style={{ marginBottom: 0 }}
            >
              <TimePicker format={hoursMinutesFormat} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col>
            <span
              style={{
                lineHeight: '32px',
                fontSize: '16px',
                color: '#969696',
                fontWeight: 500,
              }}
            >
              <FormattedMessage
                id="Common.SendingDay"
                defaultMessage="День отправления"
              />
            </span>
          </Col>
          <Col span={16}>
            <Form.Item
              name={['schedule', 'days']}
              initialValue={defaultValues.schedule.days}
              style={{ marginBottom: 0 }}
              rules={[{ required: selectedFrequency !== Frequency.DAY }]}
            >
              {selectedFrequency === Frequency.DAY ? (
                <span className={styles.info}>
                  <FormattedMessage
                    id="Common.everyDay"
                    defaultMessage="Каждый день"
                  />
                </span>
              ) : (
                <Select
                  mode="multiple"
                  allowClear
                  options={
                    selectedFrequency === Frequency.MONTH
                      ? days
                      : weekDaysOptions
                  }
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        <div className={styles.sectionItem}></div>
      </div>
      <div className={styles.submitTemplateBtn}>
        <Button loading={loading} size="large" htmlType="submit" type="primary">
          {template ? (
            <FormattedMessage
              id="Common.SaveChanges"
              defaultMessage="Сохранить изменения"
            />
          ) : (
            <FormattedMessage
              id="SendingTemplate.Add"
              defaultMessage="Добавить шаблон"
            />
          )}
        </Button>
      </div>
    </Form>
  );
};

export default AddEditTemplate;
