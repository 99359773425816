import React, { useMemo } from 'react';
import { SelectWidgetConfig } from '../../types/select';
import { Select } from 'antd';
import { PlainObject } from '../../../../types/common';
import { filterFunc } from '../../helpers/selector';
import styles from './SelectWidget.module.less';

interface SelectWidgetProps {
  data: PlainObject[];
  parameter: string[];
  config: SelectWidgetConfig;
  onChange: (value: string[]) => void;
}

const defaultValueFieldName = 'value';
const defaultLabelFieldName = 'label';

const { Option } = Select;

const SelectWidget: React.FC<SelectWidgetProps> = ({
  data,
  config,
  onChange,
  parameter,
}) => {
  const {
    valueFieldName = defaultValueFieldName,
    labelFieldName = defaultLabelFieldName,
    multiple,
  } = config;

  const mode = multiple ? 'multiple' : undefined;

  const handleChange = (value: string | string[]): void => {
    if (Array.isArray(value)) {
      onChange(value);
    } else {
      onChange(value ? [value] : []);
    }
  };

  const value = useMemo(() => {
    return multiple ? parameter : parameter[0];
  }, [multiple, parameter]);

  const dataArr = data || [];

  return (
    <div className={styles.root}>
      <Select
        mode={mode}
        className={styles.root}
        showSearch={true}
        filterOption={filterFunc}
        onChange={handleChange}
        allowClear
        value={value}
        maxTagCount={1}
      >
        {dataArr?.map(item => (
          <Option key={item.id} value={String(item[valueFieldName])}>
            {item[labelFieldName]}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default SelectWidget;
